<script setup lang="ts">
import { toast } from "vue3-toastify";

import type { Modals } from "@/composables/useAppModals";
import type { DetailInvite } from "@/types";
const { add } = useSockets();
const { isMobile } = useDevice();
const route = useRoute();
const { t } = useT();
const { data: appInitData } = useAppInitData();
const { open, close } = useAppModals();
const { logoutUser } = useLogout();
const { seasonInitData } = useSeasonsData({ immediate: false });
const { onMessage, onUpdateToken } = useFirebaseNotification();
const { sendAdAction, sendAdTrigger } = usePopupAds();
const popUpAds = useState("popUpAds");
const { handleOpenGame } = useOpenGame(open);
const { refresh: refreshCheckRewardsData } = useCheckRewardsData({ immediate: false });
const { bundleOffer } = useHomePage({ immediate: false });
const refCode = useState("refCode");
const { checkDailyOpen } = useWelcomeModal(open, close);
useOnboarding();

const isLoggedIn = computed(() => appInitData.value?.isGuest === false);
const isDepositStreak = computed(() => appInitData.value?.depositStreak?.isActive);

const handleOpenModal = (modal: string) => {
	open(modal as keyof Modals);
};

useEvent(["SHOW_VERIFICATION_PHONE"], (event: Event) => {
	const { detail } = event as CustomEvent;
	open("LazyOModalPhoneConfirmation", { location: detail?.location });
});
useEvent(["COMPLETE_PROFILE_REWARD"], (event: Event) => {
	const { detail } = event as CustomEvent<{ rewardEntry: boolean; showRewardsModal: boolean }>;
	if (detail?.rewardEntry) {
		window?.$cash?.$router?.push("/");
	}

	if (detail?.showRewardsModal) {
		open("LazyOModalPhoneConfirmation", { confirmPhoneDeposit: !appInitData.value?.phone });
	}

	// TODO here should be reward complete toast
});
useEvent(["LOGOUT_AND_OPEN_AUTH_MODAL"], () => {
	if (window?.$cash?.$store?.getters?.["cash/isOpen"]) {
		window?.$cash?.$router?.push("/");
	}
	logoutUser();
	open("LazyOModalLogin");
});
useEvent(["nuxt:closeCashbox"], () => {
	sendAdTrigger("closeCashbox");
});

const showToast = (titleText: string, detail: DetailInvite) => {
	toast.success(
		`
			<div class="toast-invite-title text-cannes">${titleText}</div>
			<div class="text-chanda">${t("You earned")}
				${detail.coins ? numberFormat(Number(detail.coins)) : "0"} ${t("FUN Coins")}
				${detail.entries ? numberFormat(Number(detail.entries)) : "0"} ${t("CITY Coins.")}
			</div>
		`,
		{
			toastId: detail.action,
			icon: h("i", { class: "toast-icon icon-goal" }),
			closeButton: () => h("i", { class: "toast-icon icon-close" }),
			dangerouslyHTMLString: true,
			theme: toast.THEME.DARK,
			position: toast.POSITION.BOTTOM_RIGHT,
			transition: toast.TRANSITIONS.SLIDE,
			autoClose: 5000
		}
	);
};

add("invite", (detail: DetailInvite) => {
	if (detail.action === "friend-qualified") {
		setTimeout(() => {
			showToast(t("Your friend get qualified!"), detail);
		}, 500);
	}
	if (detail.action === "player-qualified") {
		setTimeout(() => {
			showToast(t("You get qualified!"), detail);
		}, 500);
	}
});

add("popUpAds", (detail: EventData<PopUpAdData[]>) => {
	if (detail?.data?.length) {
		popUpAds.value = detail.data.sort((modalA, modalB) => modalA.popup_priority - modalB.popup_priority);
	}
});

if (process.client) {
	watch(isLoggedIn, (value) => {
		if (value && !appInitData?.value?.emailConfirmed) {
			open("LazyOModalEmailConfirm");
		}
	});

	watch([isLoggedIn, bundleOffer], ([newLoggedIn, newBundleOffer]) => {
		if (newLoggedIn && newBundleOffer) {
			checkDailyOpen({ storage: "showBundleOffer", modal: "LazyOModalBundle" });
		}
	});
}

onMounted(() => {
	const { invited_by: invitedBy, refcode } = route.query;

	if (invitedBy) {
		dispatchGAEvent({
			event: "promocode",
			location: "deeplink",
			step: "success"
		});
	}

	if (refcode) {
		refCode.value = refcode;
		refreshCheckRewardsData();
	}

	pushNotificationsAddEventListener("message", onMessage);
	pushNotificationsAddEventListener("updateToken", onUpdateToken);
});

onUnmounted(() => {
	pushNotificationsRemoveEventListener("message", onMessage);
	pushNotificationsRemoveEventListener("updateToken", onUpdateToken);
});
</script>
<template>
	<div class="layout">
		<ClientOnly v-if="appInitData?.isGuest === false">
			<LazyCash />
		</ClientOnly>
		<ClientOnly>
			<LazyOCookieControl />
			<LazyODynamicAdModal :openGame="handleOpenGame" @send-ad-action="sendAdAction" @open-modal="handleOpenModal" />
		</ClientOnly>
		<div class="header-wrapper">
			<LazyODepositStreakGiftLabel v-if="isDepositStreak" />
			<LazyOSeasonAsideWidget v-if="seasonInitData?.isEnabled" />
			<LazyOVipClubAsideWheel v-else />
			<OHeader />
		</div>
		<main>
			<div
				class="page-content"
				:class="{ 'page-content--offset': isLoggedIn || route.path !== '/', 'is-guest': !isLoggedIn }"
			>
				<div class="wrapper">
					<slot />
				</div>
			</div>
		</main>

		<OLazyComponent id="lazy-footer" :height="isMobile ? 920 : 677">
			<LazyOFooter :class="{ 'is-logged': isLoggedIn }" />
		</OLazyComponent>

		<LazyOMagicBoxesAudio v-if="appInitData?.isGuest === false" />
	</div>
	<ASvgCollection />
</template>

<style lang="scss">
.Toastify {
	#friend-qualified,
	#player-qualified {
		width: 312px;
		padding: gutter(2);
		max-width: 100%;
		margin: auto;

		.toast-invite-title {
			margin-bottom: gutter(0.75);
			line-height: 21px;
		}
		.toast-invite-content {
			line-height: 24px;
		}
		.Toastify {
			&__toast-body {
				display: inline-flex;
				align-items: flex-start;
				gap: gutter(2);
				color: var(--clara);
			}
			&__toast-icon {
				width: 44px;
				height: 45px;
				flex-shrink: 0;
				margin: 0;
			}
			&__toast-body {
				white-space: normal;
			}
			&__toast {
				border-radius: 8px;
				border: 1px solid var(--comilla);
				background-color: var(--celaya);
				padding: gutter(2);
				min-height: 65px;
			}
		}
		.icon-goal {
			width: 100%;
			height: 100%;
			background-image: url(/public/nuxt-img/referral/friends-qualified.png);
		}
	}
	.icon-favorite {
		&.add {
			background-image: url(/assets/icons/20/favorite-add.svg);
		}

		&.remove {
			background-image: url(/assets/icons/20/favorite-remove.svg);
		}
	}
}
</style>

<style lang="scss" scoped>
.layout {
	min-height: 100dvh;
	display: flex;
	flex-direction: column;

	@include media-breakpoint-down(lg) {
		footer.is-logged {
			margin-bottom: 56px;
		}
	}
}
.wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin: 0 auto;
}
.header-wrapper {
	width: 100%;
	margin-left: auto;
	position: relative;
	z-index: 20;

	&:has(.email-notify) {
		@include media-breakpoint-up(lg) {
			margin-top: gutter(6.875);
		}

		~ main {
			@include media-breakpoint-down(lg) {
				margin-top: gutter(12);
			}
			@include media-breakpoint-down(md) {
				margin-top: gutter(14.5);
			}

			@media (max-width: 400px) {
				margin-top: gutter(17);
			}
		}
	}
}

main {
	flex: 1;
}

.page-content {
	position: relative;
	display: block;

	&--offset {
		margin-top: gutter(13);

		@include media-breakpoint-down(lg) {
			margin-top: gutter(7);

			&.is-guest {
				margin-top: 0;
			}
		}
	}
}
</style>
