import { apiClient } from "@netgame/openapi";
import { useStorage } from "@vueuse/core";
import dayjs from "dayjs";

import useAppInitData from "./useAppInitData";

const isCouldBeSubscribed = typeof Notification === "function" && typeof navigator === "object";

const useFirebaseNotification = () => {
	const { data: appInit } = useAppInitData();

	const time = useStorage<string | null>("timestampFirebaseNotifModal", null);

	const timeHasPassed = computed(() => (time.value ? dayjs().isAfter(time.value) : true));

	const showFirebaseNotificationModal = computed(
		() => !appInit.value?.isGuest && timeHasPassed.value && isCouldBeSubscribed && Notification.permission === "default"
	);

	const onMessage = (event: CustomEvent) => {
		dispatchGAEvent({
			event: "push_opened",
			campaingName: event.detail.notification?.title || "",
			playerId: appInit.value?.login || ""
		});
	};

	const handleSendFirebaseToken = (token: string) => {
		apiClient({
			path: "/rest/player/firebase-token/",
			method: "post",
			parameters: { body: { token, login: appInit.value?.login } }
		});
	};

	const onUpdateToken = (event: CustomEvent) => {
		if (appInit.value?.isGuest === true) {
			return;
		}
		handleSendFirebaseToken(event.detail);
	};

	return { showFirebaseNotificationModal, onMessage, onUpdateToken };
};

export default useFirebaseNotification;
